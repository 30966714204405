import { styled } from '@mui/system'
import { Box, Grid as MuiGrid } from '@mui/material'
import Typography from '../../atoms/typography'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['reverse', 'hasSiblings', 'aboveTheFold'].includes(prop)

const ImageColumn = styled('div')`
	order: ${({ reverse }) => (reverse ? '1' : '2')};
	width: 100%;
	img {
		border-radius: ${({ theme }) => theme.spacing(5)};
	}
`

const TextColumn = styled('div')`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: 48px ${({ theme }) => theme.spacing(3)};
	${({ theme }) => theme.breakpoints.up('sm')} {
		padding: 0 80px;
	}
	> .description {
		margin-top: ${({ theme }) => theme.spacing(4)};
		${({ theme }) => theme.breakpoints.up('sm')} {
			margin-top: ${({ theme }) => theme.spacing(2.5)};
		}
	}
`

const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	margin-bottom: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-bottom: 32px;
	}
`

const Container = styled(Box, {
	shouldForwardProp,
})`
	display: flex;
	align-items: center;
	flex-direction: column;
	> ${ImageColumn} {
		order: ${({ reverse }) => (reverse ? '2' : '1')};
		height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		.MuiLink-root {
			height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		}
	}
	> ${TextColumn} {
		order: ${({ reverse }) => (reverse ? '1' : '2')};
	}
	${({ theme }) => theme.breakpoints.up('sm')} {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '40px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: ${({ hasSiblings }) => (hasSiblings ? '68px' : '0')};
	}
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.text.secondary};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		line-height: calc(29 / 24);
	}
`

const Description = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	line-height: 1.6;
	${({ theme }) => theme.breakpoints.up('md')} {
		line-height: calc(12 / 7);
	}
`

const ButtonStyled = styled(Button)``

export { Container, ImageColumn, TextColumn, CtaContainer, ButtonStyled, Description, Title, Subtitle }
